.ql-toolbar.ql-snow .ql-formats {
	margin-right: 15px;
	margin-bottom: 15px;
}

/** Set tooltip content for bold button **/
.ql-bold:hover:after,
.ql-bold:active:after,
.ql-bold:focus:after {
	content: 'Bold';
}

/** Set tooltip content for color button **/
.ql-color:hover:after,
.ql-color:active:after,
.ql-color:focus:after {
	content: 'Color';
}

/** Set tooltip content for highlight button **/
.ql-background:hover:after,
.ql-background:active:after,
.ql-background:focus:after {
	content: 'Highlight';
}
/** Set tooltip content for align button **/
.ql-align:hover:after,
.ql-align:active:after,
.ql-align:focus:after {
	content: 'Left';
}
/** Set tooltip content for align button **/
.ql-align[value='right']:hover:after,
.ql-align[value='right']:active:after,
.ql-align[value='right']:focus:after {
	content: 'Right';
}
/** Set tooltip content for align button **/
.ql-align[value='justify']:hover:after,
.ql-align[value='justify']:active:after,
.ql-align[value='justify']:focus:after {
	content: 'Justify';
}
/** Set tooltip content for align button **/
.ql-align[value='center']:hover:after,
.ql-align[value='center']:active:after,
.ql-align[value='center']:focus:after {
	content: 'Center';
}
/** Set tooltip content for emoji button **/
.ql-emoji:hover:after,
.ql-emoji:active:after,
.ql-emoji:focus:after {
	content: 'Emoji';
}

/** Set tooltip content for indent buttons **/
.ql-indent:hover[value='-1']:after,
.ql-indent:active[value='-1']:after,
.ql-indent:focus[value='-1']:after {
	content: 'Decrease Indent';
}

/** Set tooltip content for indent buttons **/
.ql-indent:hover[value='+1']:after,
.ql-indent:active[value='+1']:after,
.ql-indent:focus[value='+1']:after {
	content: 'Increase Indent';
}
/** Set tooltip content for Italic button **/
.ql-italic:hover:after,
.ql-italic:active:after,
.ql-italic:focus:after {
	content: 'Italic';
}
/** Set tooltip content for underline button **/
.ql-underline:hover:after,
.ql-underline:active:after,
.ql-underline:focus:after {
	content: 'Underline';
}
/** Set tooltip content for strike button **/
.ql-strike:hover:after,
.ql-strike:active:after,
.ql-strike:focus:after {
	content: 'Strike';
}
/** Set tooltip content for hyperlink button **/
.ql-link:hover:after,
.ql-link:active:after,
.ql-link:focus:after {
	content: 'Link';
}
/** Set tooltip content for image button **/
.ql-image:hover:after,
.ql-image:active:after,
.ql-image:focus:after {
	content: 'Image';
}
/** Set tooltip content for lineheight button **/
.ql-lineheight:hover:after,
.ql-lineheight:active:after,
.ql-lineheight:focus:after {
	content: 'Lineheight';
}
/** Set tooltip content for clean button **/
.ql-clean:hover:after,
.ql-clean:active:after,
.ql-clean:focus:after {
	content: 'Clean';
}
/** Set tooltip content for undo button **/
.ql-undo:hover:after,
.ql-undo:active:after,
.ql-undo:focus:after {
	content: 'Undo';
}
/** Set tooltip content for redo button **/
.ql-redo:hover:after,
.ql-redo:active:after,
.ql-redo:focus:after {
	content: 'Redo';
}
/** Set tooltip content for fonts button **/
/* .ql-font:hover:after,
.ql-font:active:after,
.ql-font:focus:after {
	content: 'Fonts';
} */
/** Set tooltip content for headers button **/
/* .ql-header:hover:after,
.ql-header:active:after,
.ql-header:focus:after {
	content: 'Headers';
} */
/** Set tooltip content for sizes button **/
/* .ql-size:hover:after,
.ql-size:active:after,
.ql-size:focus:after {
	content: 'Sizes';
} */

/** Set tooltip content for quote button **/
.ql-blockquote:hover:after,
.ql-blockquote:active:after,
.ql-blockquote:focus:after {
	content: 'Quote';
}
/** Set tooltip content for code button **/
.ql-code-block:hover:after,
.ql-code-block:active:after,
.ql-code-block:focus:after {
	content: 'Code';
}
/** Set tooltip content for bullets button **/
.ql-list[value='bullet']:hover:after,
.ql-list[value='bullet']:active:after,
.ql-list[value='bullet']:focus:after {
	content: 'Bullets';
}
/** Set tooltip content for numbers button **/
.ql-list[value='ordered']:hover:after,
.ql-list[value='ordered']:active:after,
.ql-list[value='ordered']:focus:after {
	content: 'Numbers';
}

.ql-snow .ql-picker.ql-lineheight {
	width: 58px;
}
.ql-picker-options {
	width: 30px;
}
.ql-align .ql-picker-options .ql-picker-item {
	width: full;
}
.ql-snow .ql-picker.ql-lineheight {
	width: 58px;
}

.ql-snow .ql-picker.ql-size .ql-picker-options,
.ql-snow .ql-picker.ql-line-height .ql-picker-options {
	text-align: center;
	height: auto;
	overflow: auto;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.0']::before {
	content: '1.0';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.0']::before {
	content: '1.0' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before {
	content: '1.2';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
	content: '1.2' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {
	content: '1.5';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
	content: '1.5' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before {
	content: '1.6';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
	content: '1.6' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before {
	content: '1.8';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
	content: '1.8' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.0']::before {
	content: '2.0';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.0']::before {
	content: '2.0' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before {
	content: '2.4';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
	content: '2.4' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before {
	content: '2.8';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
	content: '2.8' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3.0']::before {
	content: '3.0';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3.0']::before {
	content: '3.0' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4.0']::before {
	content: '4.0';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4.0']::before {
	content: '4.0' !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5.0']::before {
	content: '5.0';
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5.0']::before {
	content: '5.0' !important;
}
.ql-snow .ql-picker-options .ql-picker-item::before :not(.ql-color-picker) {
	content: attr(data-value) !important;
}

/* fontFamilies */
.ql-snow .ql-picker.ql-font {
	width: 140px !important;
}


.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

/* font-size */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: '16px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

/* headers*/
#ql-picker-options-1 {
	width: auto;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
	content: 'Heading 1' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
	content: 'Heading 2' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
	content: 'Body' !important;
}

.ql-header {
	width: auto;
	margin: 0 10px;
}

/* codeblock */
.ql-snow .ql-editor pre.ql-syntax {
	background-color: #f6f6f6 !important;
	color: black !important;
	overflow: visible;
	padding:  10px !important;
	font-size: 17px !important;
	font-weight: 500;
}

/* blockquote { */
.ql-snow .ql-editor blockquote::before {
	content: '" ' !important;
}
.ql-snow .ql-editor blockquote::after {
	content: ' " ' !important;
	text-align: justify !important;
}

/* option picker */
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	max-height: 120px !important;
	overflow-y: auto !important;
}
/* editor  */
.text-editor {
	margin: 0 !important;
	padding: 0 !important;
	border: 1px solid #d1d5db !important;
	position: sticky !important;
}
#toolbar {
	display: block;
	background: #eaecec;
	/* width: 100%;   */
	padding: 10px 0 !important;
}

.ql-container  {
height:auto !important ; 
max-height: 400px !important;
overflow-y:auto !important;
}
.ql-editor{
	min-height: 10em;
}

.ql-tooltip.ql-editing.ql-flip {
	left: 0 !important;
}

.ql-container {
height: auto;
min-height: 100%;
  }


.ql-toolbar.ql-snow {
	padding: 5px 3px !important;
	font-size: 12px !important;
  }
  

  #editor {
	min-height:100% !important;; 
	height:auto !important;
}

.ql-toolbar.ql-snow {
	padding: 5px 3px !important;
	font-size: 12px !important;
}

#editor {
	min-height: 100% !important;
	height: auto !important;
}

@font-face {
	font-family: 'Cooper-Bold';
	src: url('../../../public/fonts/CooperHewitt-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Cooper-BoldItalic';
	src: url('../../../public/fonts/CooperHewitt-BoldItalic.otf')
		format('opentype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Cooper-Book';
	src: url('../../../public/fonts/CooperHewitt-Book.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Cooper-BookItalic';
	src: url('../../../public/fonts/CooperHewitt-BookItalic.otf')
		format('opentype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Cooper-Heavy';
	src: url('../../../public/fonts/CooperHewitt-Heavy.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Cooper-HeavyItalic';
	src: url('../../../public/fonts/CooperHewitt-HeavyItalic.otf')
		format('opentype');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: 'Cooper-LightItalic';
	src: url('../../../public/fonts/CooperHewitt-LightItalic.otf')
		format('opentype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Cooper-Light';
	src: url('../../../public/fonts/CooperHewitt-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Cooper-Medium';
	src: url('../../../public/fonts/CooperHewitt-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Cooper-MediumItalic';
	src: url('../../../public/fonts/CooperHewitt-MediumItalic.otf')
		format('opentype');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'Cooper-Semibold';
	src: url('../../../public/fonts/CooperHewitt-Semibold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Cooper-SemiboldItalic';
	src: url('../../../public/fonts/CooperHewitt-SemiboldItalic.otf')
		format('opentype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'Cooper-Thin';
	src: url('../../../public/fonts/CooperHewitt-Thin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Cooper-ThinItalic';
	src: url('../../../public/fonts/CooperHewitt-ThinItalic.otf')
		format('opentype');
	font-weight: 100;
	font-style: italic;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
	width: 3px;
}

::-webkit-scrollbar-track {
	background-color: #ebebeb;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #6d6d6d;
}
